<template>
  <div class="mian">
    <div class="m-left">
      <img class="logopic" src="../../assets/logo.png" />
      <span class="font20 logoname">Freemen</span>
      <div class="location font14" @click="cityshow()">
        <i class="el-icon-location-outline"></i>
        <span>{{ selectCity }}</span>
        <span>【切换城市】</span>
      </div>
      <div class="tab-list font16">
        <div
          :class="activenum == item.id ? 'activeclass' : ''"
          v-for="(item, index) in tablist"
          :key="index"
          @click="choosetab(item.id)"
        >
        <el-badge v-if="item.name == '消息' && newMsgNum > 0" :value="newMsgNum" class="item">
          {{ item.name }}
        </el-badge>
        <span v-else>{{ item.name }}</span>
        </div>
      </div>
    </div>
    <div class="m-right font14">
      <div
        class="weidenglu"
        @click="loginout()"
        v-if="loginstuts == false"
        style="cursor: pointer"
      >
        登录/注册
      </div>
      <div class="tel">
        <div class="telpic">
          <i class="el-icon-phone"></i>
        </div>
        <span>0571-85109006</span>
      </div>
      <div class="flex align-center" v-if="loginstuts">
        <div class="jianli">
          <el-dropdown :hide-on-click="false" @command="handleCommandtx">
            <span
              class="el-dropdown-link"
              style="display: flex; align-items: center"
            >
              简历/上传
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="upload">上传简历</el-dropdown-item>
              <el-dropdown-item command="preview">预览简历</el-dropdown-item>
              <el-dropdown-item command="all">简历中心</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <el-dropdown :hide-on-click="false" @command="handleCommandtx">
          <span
            class="el-dropdown-link"
            style="display: flex; align-items: center"
          >
            <img
                v-if="userInfo.avatar"
                style="
                width: 40px;
                height: 40px;
                border-radius: 50px;
                margin-right: 10px;
              "
                :src="'https://freemen.work/user/file/view/' + userInfo.avatar
              "
            />
            <!--
            <el-avatar
							v-if="userInfo.avatar"
							style="margin-right: 5px;"
							size="small"
							:src="'http://192.168.10.174:8200/user/file/view/' + userInfo.avatar">
						</el-avatar> -->
            <el-avatar
              v-else
              style="margin-right: 5px"
              size="small"
              src="https://freemen.work/user/file/view/files/20220317/ic_launcher_gray_1647507067319.png"
            >
            </el-avatar>
            {{ userInfo.displayName }}
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="personal">个人中心</el-dropdown-item>
            <el-dropdown-item command="accountsettings"
              >账号设置</el-dropdown-item
            >
            <el-dropdown-item command="switchidentity"
              >切换身份</el-dropdown-item
            >
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import Bus from "../../common/bus.js";
export default {
  data() {
    return {
      tablist: [
        { id: "1", name: "首页", path: "" },
        { id: "2", name: "职位", path: "" },
        { id: "3", name: "企业", path: "" },
        { id: "4", name: "消息", path: "" },
        { id: "5", name: "APP", path: "" },
      ],
      activenum: "1",
      loginstuts: false,
      selectCity: "定位中...",
      userInfo: {},
      newMsgNum: 0,
    };
  },
  created() {
    if (sessionStorage.getItem("userInfo")) {
      this.getUserInfo();
    }
    if (sessionStorage.getItem("selectCity")) {
      this.selectCity = sessionStorage.getItem("selectCity");
    } else {
      setTimeout(() => {
        this.getLocation();
      }, 1500);
    }
    let url = window.location.href;
    if (url.indexOf("/index") != -1) {
      this.activenum = "1";
    } else if (
      url.indexOf("/positionindex") != -1 ||
      url.indexOf("/serachresult") != -1
    ) {
      this.activenum = "2";
    } else if (url.indexOf("/qiyeindex") != -1) {
      this.activenum = "3";
    } else if (url.indexOf("/chatall") != -1) {
      this.activenum = "4";
    } else if (url.indexOf("/downapp") != -1) {
      this.activenum = "5";
    } else {
      this.activenum = "";
    }
    // this.$IM.removeEventHandlerAll('onReceivedMessage');
    // if(window.IMlogin){  // 检查未读消息
    //   this.getNewMsgNum(false);
    //   this.$IM.onReceivedMessage(() => {
    //     // console.log("IM.onReceivedMessage 数据接收成功 message----", message);
    //     this.getNewMsgNum(false);
    //   })
    // } else {
    //   window.noIMlogin = true;
    //   setTimeout(() => {
    //     this.$IM.onReceivedMessage(() => {
    //       // console.log("IM.onReceivedMessage 数据接收成功 message----", message);
    //       this.getNewMsgNum(false);
    //     })
    //   }, 1500);
    // }
    // this.getMenuList();
    // this.sheight = document.documentElement.scrollHeight;
    // console.log('h获取到的高度',this.sheight)
  },
  destroyed() {
    // console.log("nav on top destroyed--------------------");
    // this.$IM.removeEventHandlerAll('all');
  },
  mounted() {
    // document.body.parentNode.style.overflowY = 'hidden';
    this.checklogin();
  },
  watch: {
    $route(val) {
      // console.log("nav top, watch $route.val = ", val);
      if (val.path==="/index") {
        this.activenum = "1";
      } else if (val.path==="/positionindex" || val.path==="/serachresult") {
        this.activenum = "2";
      } else if (val.path==="/qiyeindex") {
        this.activenum = "3";
      } else if (val.path==="/chatall") {
        this.activenum = "4";
      } else if (val.path==="/downapp") {
        this.activenum = "5";
      } else {
        this.activenum = "";
      }
    },
  },
  methods: {
    getNewMsgNum(toSetRead){
      let newNum = 0;
      this.$IM.getList().then((res) => {
        // console.log("navTOP, to update the new message unread_num, result=", res);
        let channelList = res.data.channel_infos;
        if(channelList.length > 0){
          channelList.map((v)=>{
            if(v.unread_num > 0){
              if(toSetRead){
                this.$IM.read({
                  id: JSON.parse(v.meta.payload).from,
                })
              } else {
                newNum = newNum + v.unread_num;
              }
            }
          })
        }
        this.newMsgNum = newNum;
      });
    },
    getLocation() {
      // 获取当前位置
      const self = this;
      AMap.plugin("AMap.Geolocation", function () {
        var geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 10000,
        });
        geolocation.getCurrentPosition();
        AMap.event.addListener(geolocation, "complete", onComplete);
        AMap.event.addListener(geolocation, "error", onError);
        function onComplete(data) {
          // data是具体的定位信息
          self.selectCity = data.addressComponent.city;
          sessionStorage.setItem("selectCity", data.addressComponent.city);
          console.log("定位成功信息：", data);
        }
        function onError(data) {
          // 定位出错
          console.log("定位失败错误：", data);
          // 调用ip定位
          self.getLngLatLocation();
        }
      });
    },
    getLngLatLocation() {
      const self = this;
      AMap.plugin("AMap.CitySearch", function () {
        var citySearch = new AMap.CitySearch();
        citySearch.getLocalCity(function (status, result) {
          // console.log("navTop.getLngLatLocation, AMap.CitySearch().getLocalCity.result = ", result);
          if (status === "complete" && result.info === "OK") {
            self.selectCity = result.city;
            sessionStorage.setItem("selectCity", result.city);
          }
        });
      });
    },
    getUserInfo() {
      this.$api.getUserInfo("get").then((res) => {
        this.userInfo = res.data.user;
      });
    },
    checklogin() {
      let token = window.sessionStorage.getItem("token");
      if (token) {
        this.loginstuts = true;
      } else {
        this.loginstuts = false;
      }
    },
    cityshow() {
      Bus.$emit("citystuts", {
        show: true,
        selectCity: this.selectCity,
        callback: (name) => {
          this.selectCity = name;
        },
      });
    },
    choosetab(numb) {
      this.activenum = numb;
      document.documentElement.scrollTop = 0;
      switch (numb) {
        case "1":
          this.$router.push("/index");
          // window.location.href = window.location.origin + "/#/index";

          break;
        case "2":
          this.$router.push("/positionindex");
          // window.location.href = window.location.origin + "/#/positionindex";
          break;
        case "3":
          this.$router.push("/qiyeindex");
          // window.location.href = window.location.origin + "/#/qiyeindex";

          break;
        case "4":
          this.getNewMsgNum(true);
          this.$router.push("/chatall");
          // window.location.href = window.location.origin + "/#/chatall";

          break;
        case "5":
          this.$router.push("/downapp");
          // window.location.href = window.location.origin + "/#/downapp";

          break;
      }
      // window.location.reload();
    },
    handleCommandtx(command) {
      switch (command) {
        case "upload":
          this.$router.push("/information");
          break;
        case "preview":
          this.$router.push("/previewresume");
          break;
        case "all":
          this.$router.push("/information");
          break;
        case "personal":
          this.$router.push("/myfree");
          break;
        case "accountsettings":
          this.$router.push("/securityset");
          break;
        case "switchidentity":
          this.loginout();
          break;
        case "logout":
          this.loginout();
          break;
      }
    },
    loginout() {
      this.$api.outLogin("post").then(() => {
        window.sessionStorage.clear();
        this.$IM.outLogin();
        this.$router.push("/login");
      });
    },
    async getMenuList() {
      const { data } = await this.$http.get("menus");
      if (data.meta.status === 200) {
        // console.log(data.data);
        console.log("权限列表", this.$qx);
        this.menulist = this.$qx;
      } else {
        this.$message({
          type: "error",
          message: data.meta.msg,
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.mian {
  width: 1200px;
  // background-color: #404040;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .m-left {
    display: flex;
    align-items: center;
    .logopic {
      width: 46px;
      height: 46px;
      margin-right: 15px;
    }
    .logoname {
      color: #111111;
      margin-right: 20px;
    }
    .location:hover {
      cursor: pointer;
    }
    .location {
      margin-right: 50px;
      .el-icon-location-outline {
        color: #00bcff;
        margin-right: 5px;
        font-size: 15px;
      }
      span {
        color: #111111;
        margin-right: 5px;
      }
    }
    .tab-list {
      display: flex;
      align-items: center;
      color: #666666;
      div {
        margin-right: 20px;
      }
      div:hover {
        cursor: pointer;
      }
      .activeclass {
        color: #111111;
        position: relative;
        font-size: 18px;
        .item{
          transform: none;
        }
      }
      .activeclass :after {
        position: absolute;
        content: "";
        width: 28px;
        height: 3px;
        bottom: -6px;
        background: #00bcff;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .item :after{
        background: #fff;
        opacity:0.1;
      }

    }
  }
  .m-right {
    color: #111111;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    .weidenglu {
      margin-right: 80px;
    }
    .tel:hover {
      cursor: pointer;
    }
    .tel {
      display: flex;
      align-items: center;
      .telpic {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(#00bcff 0%, #63d5fe 100%);
        box-shadow: 0px 2px 9px 0px rgba(35, 24, 21, 0.22);
        border-radius: 50px;
        margin-right: 8px;
        .el-icon-phone {
          color: #ffffff;
        }
      }
    }
    .jianli {
      margin: 0 40px;
    }
    .el-dropdown-link {
      color: #111111;
    }
    .el-dropdown-link:hover {
      cursor: pointer;
    }
  }
}
</style>
